import React from "react"
import Layout from "../components/layout";
import SEO from "../components/seo";
import {Button, Card, Col, Container, CardDeck, ListGroup, Row} from "react-bootstrap";
import {BsCheckCircle} from "react-icons/bs";
import {graphql, Link, useStaticQuery} from "gatsby";
import Img from "gatsby-image";

const GetStarted = () => {
	const data = useStaticQuery(graphql`
    query {
      tracking: file(relativePath: { eq: "tracking-screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      circles: file(relativePath: { eq: "circles-screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      progress: file(relativePath: { eq: "program-progress.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      knowledge: file(relativePath: { eq: "knowledge-screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
	return (
		<Layout>
			<SEO title="Get started with Baushe - menopause app. Experience menopause on your terms."/>
			<div className="bg-light pb-5">
				<Container>
					<h1 className="text-center pt-2">Experience menopause on your terms</h1>
					<p className="text-center mb-5">A personalized program to help you live this stage of life at your best</p>
					<Col md="8" className="mx-auto">
						<CardDeck>
							<Card className="shadow-sm">
								<Card.Header className="text-secondary font-weight-bold">Standard</Card.Header>
								<Card.Body className="text-center">
									<Card.Title>
										<span className="display-4 font-weight-bold">$4.99</span>
										<p className="text-muted">
											per month
										</p>
									</Card.Title>
									<Card.Text className="text-left d-flex justify-content-center">
										<ListGroup variant="flush">
											<ListGroup.Item><BsCheckCircle className="text-success" />&nbsp;Personal assessment</ListGroup.Item>
											<ListGroup.Item><BsCheckCircle className="text-success" />&nbsp;Symptom tracker</ListGroup.Item>
											<ListGroup.Item><BsCheckCircle className="text-success" />&nbsp;Personalized insights</ListGroup.Item>
											<ListGroup.Item><BsCheckCircle className="text-success" />&nbsp;Evidence-based content</ListGroup.Item>
											<ListGroup.Item><BsCheckCircle className="text-success" />&nbsp;Community Forum</ListGroup.Item>
										</ListGroup>
									</Card.Text>
									<Button as={Link} to="/sign-up" variant="primary" size="lg">Choose Plan</Button>
									<p className="text-muted">
										<small>Cancel anytime</small>
									</p>
								</Card.Body>
							</Card>
							<Card className="shadow-sm">
								<Card.Header className="text-secondary font-weight-bold">Premium</Card.Header>
								<Card.Body className="text-center">
									<Card.Title>
										<span className="display-4 font-weight-bold">$9.99</span>
										<p className="text-muted">
											per month
										</p>
									</Card.Title>
									<Card.Text className="text-left d-flex justify-content-center">
										<ListGroup variant="flush">
											<ListGroup.Item><BsCheckCircle className="text-success" />&nbsp;Everything in standard +</ListGroup.Item>
											<ListGroup.Item><BsCheckCircle className="text-success" />&nbsp;Unique wellness program</ListGroup.Item>
											<ListGroup.Item><BsCheckCircle className="text-success" />&nbsp;Peer mentoring group</ListGroup.Item>
											<ListGroup.Item><BsCheckCircle className="text-success" />&nbsp;1-1 chat with experts</ListGroup.Item>
											<ListGroup.Item><BsCheckCircle className="text-success" />&nbsp;Full health report</ListGroup.Item>
										</ListGroup>
									</Card.Text>
									<Button as={Link} to="/sign-up" variant="primary" size="lg">Choose Plan</Button>
									<p className="text-muted">
										<small>Cancel anytime</small>
									</p>
								</Card.Body>
							</Card>
						</CardDeck>
					</Col>
				</Container>
			</div>
			<Container>
				<Row className="mt-3 text-center">
					<Col xs="12">
						<h2 className="mt-3 mb-5">What you get with Baushe subscription</h2>
					</Col>
					<Col className="d-flex flex-column align-items-center mb-5">
						<div>
							<Img style={{ width: '200px'}} className="my-3 my-md-0"  fluid={data?.tracking?.childImageSharp?.fluid}/>
						</div>
						<h5 className="mt-4 text-secondary">Symptom tracker</h5>
						<p>Understand more about your menopause by getting to know your body and its triggers</p>
					</Col>
					<Col className="d-flex flex-column align-items-center mb-5">
						<div>
							<Img style={{ width: '200px'}} className="my-3 my-md-0"  fluid={data?.progress?.childImageSharp?.fluid}/>
						</div>
						<h5 className="mt-4 text-secondary">Personalized insights</h5>
						<p>Follow your expert-designed personalized program based on your unique profile</p>
					</Col>
					<Col className="d-flex flex-column align-items-center mb-5">
						<div>
							<Img style={{ width: '200px'}} className="my-3 my-md-0"  fluid={data?.knowledge?.childImageSharp?.fluid}/>
						</div>
						<h5 className="mt-4 text-secondary">Reliable content</h5>
						<p>From expert sources to keep you up to date on the latest research</p>
					</Col>
					<Col className="d-flex flex-column align-items-center mb-5">
						<div>
							<Img style={{ width: '200px'}} className="my-3 my-md-0"  fluid={data?.circles?.childImageSharp?.fluid}/>
						</div>
						<h5 className="mt-4 text-secondary">Peer Mentoring</h5>
						<p>Learn and become accountable for your habit changes with the help of like-minded women</p>
					</Col>
				</Row>
			</Container>
		</Layout>
	)
}

export default GetStarted
